<template>
    <div>
        <div v-if="compact" class="relative">
            <label
                v-if="label"
                :for="name"
                class="absolute z-10 inline-block px-1 -mt-px text-xs font-medium bg-white rounded-t-md -top-2 left-2 text-neutral-900"
            >
                <slot></slot>
                {{ label }}</label
            >
            <Field :value="multiselectValue" :name="name" v-slot="{ errors, handleChange }">
                <Multiselect
                    v-model="multiselectValue"
                    :placeholder="placeholder"
                    :options="options"
                    :searchable="searchable"
                    :filterResults="filterResults"
                    :resolveOnLoad="resolveOnLoad"
                    :minChars="minChars"
                    :delay="delay"
                    :valueProp="valueProp"
                    :trackBy="trackBy"
                    :label="labelProp"
                    :createTag="createTag"
                    :showOptions="showOptions"
                    :max="max"
                    :mode="mode"
                    :limit="limit"
                    :noOptionsText="noOptionsText"
                    :caret="showOptions"
                    :openDirection="openDirection"
                    :class="[{ 'border-red-500': errors.length > 0 }, inputClasses]"
                    class="block w-full p-0 border-0 text-neutral-900 placeholder-neutral-500 focus:ring-0 sm:text-sm"
                    @clear="handleChange"
                    @change="
                        handleChange($event);
                        changeValue($event);
                    "
                />
            </Field>
        </div>
        <div v-else class="flex flex-col space-y-1">
            <label
                v-if="label"
                :for="name"
                class="flex items-center text-sm font-medium tracking-wide text-neutral-600"
            >
                <slot></slot>
                {{ label }}
            </label>
            <Field :value="multiselectValue" :name="name" v-slot="{ errors, handleChange }">
                <Multiselect
                    v-model="multiselectValue"
                    :placeholder="placeholder"
                    :options="options"
                    :searchable="searchable"
                    :filterResults="filterResults"
                    :resolveOnLoad="resolveOnLoad"
                    :minChars="minChars"
                    :delay="delay"
                    :valueProp="valueProp"
                    :trackBy="trackBy"
                    :label="labelProp"
                    :createTag="createTag"
                    :showOptions="showOptions"
                    :max="max"
                    :mode="mode"
                    :limit="limit"
                    :noOptionsText="noOptionsText"
                    :caret="showOptions"
                    :class="[{ 'border-red-500': errors.length > 0 }, inputClasses]"
                    class="w-full bg-white"
                    @clear="handleChange"
                    @change="
                        handleChange($event);
                        changeValue($event);
                    "
                />
            </Field>
        </div>
        <ErrorMessage :class="errorClasses" :name="name" />
    </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import Multiselect from '@vueform/multiselect';
import { ErrorMessage, Field } from 'vee-validate';

export default defineComponent({
    name: 'MultiselectField',
    model: {
        prop: 'modelValue',
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number, Object, Array],
        },
        options: {
            type: [Array, Object, Function],
            default: () => [],
        },
        mode: {
            type: String,
            default: 'single', // can be single, multiple or tags
        },
        createTag: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        filterResults: {
            type: Boolean,
            default: true,
        },
        resolveOnLoad: {
            type: Boolean,
            default: true,
        },
        minChars: {
            type: Number,
            default: 0,
        },
        delay: {
            type: Number,
            default: -1,
        },
        valueProp: {
            type: String,
            default: 'value',
        },
        trackBy: {
            type: String,
            default: 'label',
        },
        labelProp: {
            type: String,
            default: 'label',
        },
        showOptions: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        limit: {
            type: Number,
            default: -1,
        },
        noOptionsText: {
            type: String,
            default: 'The list is empty',
        },
        max: {
            type: Number,
            default: -1,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: 'bottom',
        },
        errorClasses: {
            type: String,
            default: 'text-sm text-red-600',
        },
        inputClasses: {
            type: String,
        },
    },
    components: { Multiselect, Field, ErrorMessage },
    setup(props, { emit }) {
        const multiselectValue = computed({
            set: newValue => {
                emit('update:modelValue', newValue);
            },
            get: () => props.modelValue,
        });

        const changeValue = value => {
            emit('changed', value);
        };

        return { multiselectValue, changeValue };
    },
});
</script>
