import BaseLayout from './BaseLayout.vue';
import EmptyLayout from './EmptyLayout.vue';

export { BaseLayout, EmptyLayout };

export const selectLayout = routeName => {
    switch (routeName) {
        case null:
        case undefined:
        case 'Home':
        case 'About':
        case 'Login':
        case '500':
        case '404':
        case '503':
            return EmptyLayout;
        default:
            return BaseLayout;
    }
};
