<template>
    <div ref="rootElement" />
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from 'axios';

export default defineComponent({
    name: 'SvgImage',
    props: {
        src: {
            type: String,
            required: true,
        },
        originalFill: {
            type: String,
            default: '#6C63FF',
        },
        newFill: {
            type: String,
            default: 'currentColor',
        },
    },
    setup(props) {
        const rootElement = ref();
        axios
            .get(props.src)
            .then(res => {
                if (res.status >= 200 && res.status < 400) {
                    // Replace original color
                    const data = res.data.split(props.originalFill.toLowerCase()).join(props.newFill);

                    // Parse responseText and create an SVG element
                    const domParser = new DOMParser();
                    const result = domParser.parseFromString(data, 'text/xml');
                    const svg = result.getElementsByTagName('svg')[0];
                    svg.setAttribute('width', '100%');
                    svg.setAttribute('height', '100%');

                    // Append SVG element to the dom
                    if (rootElement.value) {
                        rootElement.value.appendChild(svg);
                    }
                }
            })
            .catch(() => {
                console.error(`No SVG element found in the given file: ${props.src}`);
            });

        return { rootElement };
    },
});
</script>
