import { createApp } from 'vue';
import { configure } from 'vee-validate';
import Datepicker from 'vue3-date-time-picker';
import VueApexCharts from 'vue3-apexcharts';
import VTooltip from 'v-tooltip';
import router from '../router';
import App from './App.vue';
import store from './store';
import 'v-tooltip/dist/v-tooltip.css';
import Toast from 'vue-toastification';
import validationMessages from './validation-messages';
import vClickOutside from 'click-outside-vue3';
// CSS imports
import './styles/tailwind.css';

const app = createApp(App);

// Custom validation messages
configure({
    validateOnInput: true,
    generateMessage: context => validationMessages(context),
});

// Toast options
const toastOptions = {
    position: 'bottom-right',
    timeout: 4000,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    maxToasts: 3,
};

app.use(router)
    .use(store)
    .use(VueApexCharts)
    .use(VTooltip)
    .use(Toast, toastOptions)
    .component('Datepicker', Datepicker)
    .use(vClickOutside)
    .mount('#app');
