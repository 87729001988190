<template>
    <div
        class="relative w-full h-6 overflow-hidden bg-neutral-200"
        :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
    >
        <div
            class="h-full progressbar"
            :class="[`${color}`, { 'absolute top-0': indeterminate }, { 'rounded-full': rounded }]"
            role="progressbar"
            :style="{ width: `${percentage}%` }"
            :aria-valuenow="percentage"
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <span class="flex items-center h-full">
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProgressBar',
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'bg-primary-500',
        },
        percentage: {
            type: Number,
            default: 0,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        indeterminate: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
});
</script>

<style scoped>
@keyframes progress-indeterminate {
    0% {
        width: 30%;
        left: -40%;
    }
    60% {
        left: 100%;
        width: 100%;
    }
    to {
        left: 100%;
        width: 0;
    }
}
.progressbar {
    transition: width 0.25s ease;
}
.indeterminate .progressbar {
    animation: progress-indeterminate 1.4s ease infinite;
}
</style>
