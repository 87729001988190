<template>
    <div>
        <div
            :class="
                vertical
                    ? 'space-y-4 flex flex-col'
                    : 'flex space-y-4  flex-col flex-wrap md:space-x-6 md:space-y-0 md:flex-row md:leading-8'
            "
        >
            <Field :name="name" v-slot="{ field, handleChange }" :value="modelValue">
                <div class="flex flex-row items-center space-x-3 " v-for="(option, idx) in options" :key="idx">
                    <input
                        :id="option.name"
                        type="radio"
                        class=" text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:opacity-25"
                        v-model="field.value"
                        @input="updateValue($event.target.value)"
                        @change="handleChange"
                        :value="option.value"
                        :name="name"
                        :disabled="disabled"
                    />

                    <slot :name="option.name" :option="option">
                        <label :for="option.name" class="text-neutral-700">
                            <span class="whitespace-nowrap" :class="{ 'font-medium': option.description }">{{
                                option.label
                            }}</span>
                            <p class="block text-sm text-neutral-500 whitespace-nowrap" v-if="option.description">
                                {{ option.description }}
                            </p>
                        </label>
                    </slot>
                </div>
            </Field>
        </div>
        <ErrorMessage class="text-sm text-red-600" :name="name" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';

export default defineComponent({
    name: 'RadioButtons',
    model: {
        prop: 'modelValue',
    },
    emits: ['update:modelValue', 'changed'],
    components: {
        Field,
        ErrorMessage,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: [String, Boolean, Number],
        },
        name: {
            type: String,
            required: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isBoolean: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const updateValue = value => {
            const newValue = props.isBoolean ? value === 'true' : value;
            emit('update:modelValue', newValue);
            emit('changed', newValue);
        };

        return {
            updateValue,
        };
    },
});
</script>
