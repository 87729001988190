import { secured, plain } from '@/config/axios';

const resource = 'auth';

export default {
    login: data => plain.post(`${resource}/login`, data),
    keycloakLogin: (userId, userToken, userRefreshToken) =>
        plain.post(`${resource}/keycloak-login`, {
            profile: userId,
            accessToken: userToken,
            refreshToken: userRefreshToken,
        }),
    user: () =>
        secured.get(`${resource}/user`, {
            headers: {
                'Cache-Control': 'no-cache',
            },
        }),
    logout: () => secured.post(`${resource}/logout`),
    registerUser: data => secured.post(`${resource}/register`, data),
    updateUser: data => secured.put(`${resource}/user`, data),
    changePassword: data => secured.put(`${resource}/password`, data),
};
