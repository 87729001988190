import store from '../store';

export const GuestOnly = (to, from, next) => {
    // If the user is not authenticated, continue with the route
    if (!store.getters.isAuthenticated) {
        return next();
    }

    // Otherwise, redirect to home
    return next({ name: 'home' });
};
