<template>
    <div>
        <div class="text-sm font-medium tracking-wide text-neutral-600" v-if="label">{{ label }}</div>
        <div class="flex items-center space-x-4 " :class="{ 'mt-1': !!label }">
            <div class="flex items-center space-x-1 text-neutral-500">
                <arrow-circle-up-icon
                    v-tooltip="'Value Increased'"
                    class="w-5 h-5 text-green-500"
                    v-if="previousValue && currentValue > previousValue && contractStatus !== 'Active'"
                />
                <arrow-circle-down-icon
                    v-tooltip="'Value Decreased'"
                    class="w-5 h-5 text-red-400"
                    v-else-if="previousValue && currentValue < previousValue && contractStatus !== 'Active'"
                />
                <span>{{ value }}</span>
            </div>
            <div
                class="text-sm italic text-neutral-400 "
                v-if="previousValue && currentValue !== previousValue && contractStatus !== 'Active'"
            >
                Previous: {{ previousValue }}
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { ArrowCircleUpIcon, ArrowCircleDownIcon } from '@heroicons/vue/solid';

export default defineComponent({
    name: 'FormItem',
    components: {
        ArrowCircleUpIcon,
        ArrowCircleDownIcon,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        previousValue: {
            type: [String, Number],
            default: null,
        },
        contractStatus: {
            type: String,
            default: () => null,
        },
    },
    setup(props) {
        const currentValue = computed(() => Number(String(props.value).split(' ')[0]));

        return { currentValue };
    },
});
</script>
