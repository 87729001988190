<template>
    <Menu as="div" class="relative z-10 ml-3">
        <div class="flex items-center space-x-6">
            <Cart v-if="isAggregator" />
            <menu-button
                class="flex flex-row items-center space-x-2 text-sm text-primary-600 group focus:outline-none focus:shadow-solid"
            >
                <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-primary-600">
                    <span class="font-medium leading-none text-white uppercase">{{ userInitials }}</span>
                </span>
                <div class="hidden text-left md:block">
                    <div class="font-medium" v-if="fullName">
                        <span>{{ fullName }}</span>
                    </div>
                </div>
                <chevron-down-icon class="w-4 h-4 ml-1" />
            </menu-button>
        </div>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <menu-items
                class="absolute right-0  py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                :class="isAggregator ? 'w-56' : 'w-64'"
            >
                <div class="flex flex-col px-4 py-3 space-y-1">
                    <p class="text-sm">Signed in as {{ userRole }}</p>
                    <p v-if="fullName" class="text-sm font-medium truncate text-primary-600">
                        {{ fullName }}
                    </p>
                </div>
                <hr />

                <menu-item v-slot="{ active }">
                    <router-link
                        to="/settings"
                        :class="[
                            active ? 'bg-neutral-100' : '',
                            'group flex items-center px-4 py-2 text-sm text-neutral-500 hover:text-neutral-700',
                        ]"
                    >
                        <cog-icon class="w-5 h-5 mr-3 text-neutral-500 group-hover:text-neutral-700" />
                        Settings</router-link
                    >
                </menu-item>

                <menu-item v-slot="{ active }">
                    <a
                        href="#"
                        :class="[
                            active ? 'bg-neutral-100' : '',
                            'group flex items-center px-4 py-2 text-sm text-neutral-500 hover:text-neutral-700',
                        ]"
                        @click="logout()"
                    >
                        <logout-icon class="w-5 h-5 mr-3 text-neutral-500 group-hover:text-neutral-700" />
                        Sign out</a
                    >
                </menu-item>
            </menu-items>
        </transition>
    </Menu>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { LogoutIcon, ChevronDownIcon, CogIcon } from '@heroicons/vue/outline';
import { Cart } from '../../components';
import { useRouter } from 'vue-router';
import { useCart } from '@/composables';
import AuthAPI from '../../api/auth';
import store from '@/store';

export default defineComponent({
    name: 'UserMenu',
    components: {
        Cart,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        CogIcon,
        LogoutIcon,
        ChevronDownIcon,
    },
    setup() {
        const router = useRouter();
        const user = computed(() => store.state.user);
        const fullName = computed(() => {
            if (user.value) {
                return `${user.value.firstName} ${user.value.lastName}`;
            }
            return '';
        });
        const { clearCart } = useCart();
        const isAggregator = computed(() => store.getters.isAggregator);
        const userRole = store.getters.isAggregator ? 'Aggregator' : 'Building Occupant';

        const userInitials = computed(() => {
            return fullName.value
                ? fullName.value
                      .split(' ')
                      .map(n => n[0])
                      .join('')
                : '';
        });

        const logout = async () => {
            await clearCart();
            await store.commit('CLEAR_NETWORK');
            await store.commit('CLEAR_USER');
            await AuthAPI.logout();
            await router.push('/');
        };

        return {
            fullName,
            userInitials,
            userRole,
            logout,
            isAggregator,
        };
    },
});
</script>
