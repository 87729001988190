import store from '../store';
import AuthAPI from '../api/auth';
import Keycloak from '../api/keycloak';

export const AuthGuard = async (to, from, next) => {
    if (store.getters.isAuthenticated) {
        return next();
    }

    try {
        // If not, try to get user (auto-login, if there's a valid cookie)
        const { data: user } = await AuthAPI.user();
        store.commit('SET_USER', user);

        return next();
    } catch (e) {
        if (Keycloak.isEnabled()) {
            return Keycloak.login(to.name, next);
        }
        // Otherwise, redirect log in
        return next({ name: 'home', params: { path: to.fullPath } });
    }
};
