<template>
    <component class="h-screen overflow-hidden font-inter" :is="layout" v-if="isReady">
        <router-view :layout="layout" />
    </component>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { selectLayout } from './layouts';
import { useRoute } from 'vue-router';
import AuthAPI from './api/auth';
import store from '@/store';

export default defineComponent({
    name: 'App',
    setup() {
        const route = useRoute();
        const isReady = ref(false);
        const layout = computed(() => selectLayout(route.name));

        if (!store.state.user) {
            AuthAPI.user()
                .then(({ data }) => {
                    store.commit('SET_USER', data);
                    if (process.env.VUE_APP_ETH_NODE) {
                        store.dispatch('setWallet');
                        store.dispatch('createProvider', process.env.VUE_APP_ETH_NODE);
                    }
                    isReady.value = true;
                })
                .catch(() => {
                    isReady.value = true;
                });
        } else {
            if (process.env.VUE_APP_ETH_NODE) {
                store.dispatch('setWallet');
                store.dispatch('createProvider', process.env.VUE_APP_ETH_NODE);
            }
        }

        return {
            layout,
            isReady,
        };
    },
});
</script>
