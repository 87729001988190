import { createWebHistory, createRouter } from 'vue-router';
import { AuthGuard } from './src/router/auth.guard';
import { GuestOnly } from './src/router/guest.guard';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
        meta: { title: 'Login' },
        beforeEnter: GuestOnly,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
        meta: { title: 'Settings' },
        beforeEnter: AuthGuard,
    },
    {
        path: '/overview',
        name: 'Overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/overview/Overview.vue'),
        beforeEnter: AuthGuard,
    },
    {
        path: '/assets/:id',
        name: 'Asset Details',
        props: true,
        component: () => import(/* webpackChunkName: "asset" */ '@/views/overview/AssetDetails.vue'),
        beforeEnter: AuthGuard,
    },
    {
        path: '/contracts',
        name: 'Flexibility Contracts',
        component: () => import(/* webpackChunkName: "contract" */ '@/views/contract/ContractsList.vue'),
        beforeEnter: AuthGuard,
    },
    {
        path: '/contracts/:id',
        name: 'Create Contract',
        props: true,
        component: () => import(/* webpackChunkName: "contract" */ '@/views/contract/Contract.vue'),
        beforeEnter: AuthGuard,
    },
    {
        path: '/contracts/:id/performance-overview',
        name: 'Performance Overview',
        props: true,
        component: () => import(/* webpackChunkName: "contract" */ '@/views/contract/PerformanceOverview.vue'),
    },
    {
        name: '500',
        path: '/500',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/Error500.vue'),
        meta: { title: '500' },
    },
    {
        name: '404',
        path: '/404',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/Error404.vue'),
        meta: { title: '404' },
    },
    // 404 route must be last
    // {
    //     path: '/:pathMatch(.*)*',
    //     name: '404',
    //     component: () => import(/* webpackChunkName: "overview" */ '@/views/Error404.vue'),
    // },
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/Error404.vue'),
        meta: {
            requiresAuth: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
