<template>
    <Form :validation-schema="validationSchema" v-slot="{ meta }" @submit="null">
        <div class="fixed inset-x-0 bottom-0 z-10 sm:inset-0 sm:flex sm:items-center sm:justify-center">
            <transition
                enter-active-class="duration-300 ease-out"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-black opacity-70"></div>
                </div>
            </transition>

            <transition
                enter-active-class="duration-300 ease-out"
                enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
                enter-to-class="translate-y-0 opacity-100 sm:scale-100"
                leave-active-class="duration-200 ease-in"
                leave-class="translate-y-0 opacity-100 sm:scale-100"
                leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            >
                <div class="transition-all transform bg-white rounded-lg shadow-xl" :class="width">
                    <div class="bg-white rounded-lg">
                        <div class="px-6 pt-5 pb-4 sm:flex sm:items-start">
                            <slot name="icon" />
                            <div class="w-full mt-2 text-center sm:mt-0 sm:text-left">
                                <div class="text-lg font-medium leading-6 text-gray-900">
                                    {{ title }}
                                </div>
                                <div class="flex flex-col items-baseline mt-2 space-y-4">
                                    <div class="w-full mt-4"><slot /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <span class="flex items-center w-full px-6 mb-3 rounded-md sm:mt-0 sm:w-auto">
                            <slot name="loader"></slot>
                        </span>
                        <div class="p-6 sm:flex sm:flex-row-reverse">
                            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto" v-if="!showCloseButton">
                                <button
                                    @click.stop="$emit('save')"
                                    type="button"
                                    :disabled="!meta.valid || !allowSave || loading"
                                    :class="[
                                        'inline-flex justify-center w-full px-6 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm focus:outline-none sm:text-sm sm:leading-5 bg-primary-600 hover:bg-primary-500 focus:border-primary-700 focus:ring-primary disabled:pointer-events-none disabled:opacity-50',
                                        !meta.valid || !allowSave || loading ? 'pointer-events-none' : '',
                                    ]"
                                >
                                    {{ saveButtonText }}
                                </button>
                            </span>
                            <span class="flex w-full mt-3 rounded-md sm:mt-0 sm:w-auto" v-if="!showCloseButton">
                                <button
                                    @click.stop="$emit('cancel')"
                                    :disabled="loading"
                                    type="button"
                                    :class="[
                                        'inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out rounded-md text-neutral-700 hover:text-neutral-500 focus:outline-none focus:border-blue-300 focus:ring sm:text-sm sm:leading-5 disabled:opacity-50',
                                        loading ? 'pointer-events-none' : '',
                                    ]"
                                >
                                    {{ cancelButtonText }}
                                </button>
                            </span>
                            <span
                                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
                                v-if="showCloseButton"
                            >
                                <button
                                    @click.stop="$emit('close')"
                                    :disabled="loading"
                                    type="button"
                                    :class="[
                                        'inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border rounded-md shadow-sm border-neutral-300 text-neutral-700 hover:text-neutral-500 focus:outline-none focus:border-blue-300 focus:ring sm:text-sm sm:leading-5',
                                        loading ? 'pointer-events-none' : '',
                                    ]"
                                >
                                    Close
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </Form>
</template>

<script>
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';

export default defineComponent({
    name: 'FormModal',
    props: {
        title: {
            type: String,
            required: true,
        },
        saveButtonText: {
            type: String,
            default: 'Save',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        width: {
            type: String,
            default: 'min-w-1/3 sm:max-w-lg sm:w-full',
        },
        allowSave: {
            type: Boolean,
            default: true,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        validationSchema: {
            type: Object,
            default: null,
        },
    },
    components: { Form },
});
</script>
