const validationMessages = context => {
    const fieldName = context.field.replace(/([A-Z])/g, ' $1').toLowerCase();
    switch (context.rule?.name) {
        case 'required':
            return `The ${fieldName} is required`;
        case 'email':
            return `Email is not valid`;
        case 'confirmed':
            return `Passwords do not match`;
        case 'max':
            return `The ${fieldName} cannot be longer than ${context.rule.params[0]} characters`;
        case 'min':
            return `The ${fieldName} cannot be shorter than ${context.rule.params[0]} characters`;
        case 'max_value':
            return `The ${fieldName} cannot be greater than ${context.rule.params[0]}`;
        case 'min_value':
            return `The ${fieldName} cannot be smaller than ${context.rule.params[0]}`;
        default:
            return `The field ${context.field} is invalid`;
    }
};

export default validationMessages;
