<template>
    <div class="flex flex-col space-y-1">
        <label class="flex items-center text-sm font-medium tracking-wide text-neutral-600">
            {{ label }}
        </label>
        <div>
            <Field :name="name" v-slot="{ errors, handleChange }" :value="inputValue">
                <Datepicker
                    v-model="inputValue"
                    :enableTimePicker="options.enableTimePicker"
                    :timePicker="options.timePicker"
                    :uid="uid"
                    autoApply
                    :minDate="minDate"
                    :maxDate="maxDate"
                    :minTime="minTime"
                    :maxTime="maxTime"
                    @update:modelValue="handleChange"
                >
                    <template #trigger>
                        <div class="flex rounded-md shadow-sm">
                            <span
                                class="inline-flex items-center px-3 text-sm border border-r-0 text-neutral-500 border-neutral-300 rounded-l-md bg-neutral-50"
                            >
                                <calendar-icon
                                    class="w-5 h-5 text-neutral-500"
                                    v-if="mode === 'datetime' || mode === 'date'"
                                />
                                <clock-icon class="w-5 h-5" v-else />
                            </span>
                            <input
                                class="block w-full px-3 py-2 border rounded-none shadow-sm appearance-none focus:border-2 rounded-r-md placeholder-neutral-400 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                :class="{ 'border-red-500': errors.length > 0 }"
                                :value="formattedValue"
                                :placeholder="placeholder"
                                readonly
                            />
                        </div>
                    </template>
                </Datepicker>
            </Field>
        </div>
        <ErrorMessage class="text-sm text-red-600" :name="name" />
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { CalendarIcon, ClockIcon } from '@heroicons/vue/outline';
import { Field, ErrorMessage } from 'vee-validate';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'Calendar',
    components: {
        CalendarIcon,
        ClockIcon,
        Field,
        ErrorMessage,
    },
    props: {
        modelValue: {
            type: [String, Number, Date, Object],
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        uid: {
            type: String,
            required: true,
        },
        name: {
            type: String,
        },
        mode: {
            type: String,
            default: 'datetime',
        },
        minDate: {
            type: Date,
            defautl: null,
        },
        maxDate: {
            type: Date,
            defautl: null,
        },
        minTime: {
            type: Object,
            defautl: {},
        },
        maxTime: {
            type: Object,
            defautl: {},
        },
    },
    setup(props, { emit }) {
        const inputValue = computed({
            set: newValue => {
                emit('update:modelValue', newValue);
            },
            get: () => props.modelValue,
        });

        const format = value => {
            return parseInt(value, 10) < 10 ? `0${value}` : value;
        };

        const formattedValue = computed(() => {
            if (!inputValue.value) return null;
            switch (props.mode) {
                case 'date':
                    return dayjs(inputValue.value).format('DD/MM/YYYY');
                case 'time':
                    if (inputValue.value.hours === null || inputValue.value.minutes === null) return null;
                    return `${format(inputValue.value.hours)}:${format(inputValue.value.minutes)}`;
                default:
                    return dayjs(inputValue.value).format('DD/MM/YYYY, HH:mm');
            }
        });

        const options = computed(() => {
            switch (props.mode) {
                case 'date':
                    return { enableTimePicker: false, timePicker: false };
                case 'time':
                    return { enableTimePicker: true, timePicker: true };
                default:
                    return { enableTimePicker: true, timePicker: false };
            }
        });

        return { inputValue, options, formattedValue };
    },
});
</script>
