<template>
    <header class="bg-neutral-200">
        <nav class="px-8 mx-auto" aria-label="Top">
            <div class="flex items-center justify-between w-full py-4 border-b border-primary-300 lg:border-none">
                <div class="flex items-end">
                    <router-link :to="{ name: 'Home', path: '/home' }" class="flex-shrink-0" exact>
                        <a href="#">
                            <span class="sr-only">Excess</span>
                            <img class="w-auto h-8" src="@/assets/logo-on-white-excess.png" alt="" />
                        </a>
                    </router-link>
                    <div class="hidden ml-10 space-x-4 lg:block">
                        <router-link v-for="link in navigation" :key="link.name" :to="link.path">
                            <a
                                href="#"
                                class="px-2 py-1 text-base font-medium text-primary-400 hover:text-primary-700"
                                :class="{
                                    'font-bold text-primary-700': routeName === link.name,
                                }"
                            >
                                {{ link.name }}
                            </a>
                        </router-link>
                    </div>
                </div>
                <div class="ml-10 space-x-4">
                    <UserMenu />
                </div>
            </div>
            <div class="flex flex-wrap justify-center py-4 space-x-6 lg:hidden">
                <router-link v-for="link in navigation" :key="link.name" :to="link.path">
                    <a
                        href="#"
                        class="px-2 py-1 text-base font-medium text-primary-400 hover:text-primary-700"
                        :class="{
                            'font-bold text-primary-700': routeName === link.name,
                        }"
                    >
                        {{ link.name }}
                    </a>
                </router-link>
            </div>
        </nav>
    </header>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import UserMenu from './UserMenu.vue';

const navigation = [
    { name: 'Overview', path: '/overview' },
    { name: 'Flexibility Contracts', path: '/contracts' },
];

export default defineComponent({
    name: 'PageHeader',
    components: {
        UserMenu,
    },
    setup() {
        const route = useRoute();
        const routeName = computed(() => route.name);

        return {
            navigation,
            routeName,
        };
    },
});
</script>
