<template>
    <div class="px-8 py-4 bg-primary-600 border-b border-primary-600 sm:flex sm:items-center sm:justify-between">
        <h3 class="flex items-center text-xl leading-6 text-neutral-200">{{ title }} <slot name="status" /></h3>
        <div class="flex mt-5 sm:mt-0 lg:ml-4">
            <slot v-if="!loading" />
            <fulfilling-bouncing-circle-spinner
                class="ml-4"
                :animation-duration="3000"
                :size="40"
                color="#1d746a"
                v-else
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';

export default defineComponent({
    name: 'PageTitle',
    components: {
        FulfillingBouncingCircleSpinner,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
