<template>
    <div>
        <div v-click-outside="closeCart">
            <div class="flex items-center ml-4 md:ml-6">
                <div class="relative ml-3">
                    <div>
                        <button
                            class="p-1 -mx-1  rounded-full text-primary-300 hover:bg-neutral-300 hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
                            type="button"
                            @click="isCartOpen = !isCartOpen"
                            :disabled="!canOpenCart"
                        >
                            <span class="sr-only">View Cluster</span>
                            <div class="flex p-1">
                                <svg width="1.3em" height="1.3em" viewBox="0 0 512 512">
                                    <path
                                        fill="currentColor"
                                        d="M459.26 96L328 225.7V96h-34.525L168 223.267V16H16v480h480V96ZM464 464H48V48h88v216h36.778L296 139.018V264h38.764L464 136.3Z"
                                    ></path>
                                    <path
                                        fill="primary"
                                        d="M136 328v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-72h32v32h-32zm0 72h32v32h-32z"
                                    ></path>
                                </svg>

                                <div class="absolute flex px-3">
                                    <div
                                        class="inline-block w-4 h-4 text-xs leading-4 text-center text-white bg-red-600 rounded-full"
                                        v-if="cart.length > 0"
                                        :title="'You have ' + cart.length + ' devices in your cluster'"
                                    >
                                        {{ cart.length > 9 ? '9+' : cart.length }}
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <div class="absolute right-0 z-20 mt-2 origin-top rounded-md shadow-lg w-120" v-if="isCartOpen">
                            <div class="py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5 ">
                                <template v-if="cart.length > 0">
                                    <div class="flex items-center justify-between px-4 ">
                                        <div class="block py-2 text-md font-bold text-neutral-700  flex-col">
                                            Cluster
                                            <div class="text-sm font-normal">
                                                Total Flexibilities: {{ cart.length }}
                                            </div>
                                        </div>
                                        <!-- <button
                                            title="Remoce all assets from Cart"
                                            class="py-1 text-xs outline-none text-neutral-600 hover:text-neutral-700"
                                            @click="showClearCartModal = true"
                                        >
                                            Clear Cart
                                        </button> -->
                                    </div>
                                    <hr />
                                    <scrollbar>
                                        <div class="mt-6 h-80">
                                            <ul role="list" class="-my-6 divide-y divide-gray-200">
                                                <CartListItem v-for="asset in cart" :asset="asset" :key="asset.id" />
                                            </ul>
                                        </div>
                                    </scrollbar>
                                </template>
                                <div class="flex items-center p-4 text-sm" v-else>
                                    <InformationCircleIcon class="w-5 h-5 mr-1 text-neutral-400" />
                                    No Flexibilities in the Cluster
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <!-- <portal to="modals">
            <confirm-modal
                v-if="showClearCartModal"
                @cancel="showClearCartModal = false"
                @ok="clearCartAssets"
                color="yellow"
                title="Clear Cart"
                message="Are you sure you want to remove all assets from the cart?"
                okButtonText="Confirm"
            />
        </portal> -->
    </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import store from '../store';
import { useCart } from '../composables';
import { Scrollbar } from '../components';
import { InformationCircleIcon } from '@heroicons/vue/solid';
import CartListItem from './CartListItem.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'Cart',
    components: {
        CartListItem,

        Scrollbar,
        InformationCircleIcon,
    },
    setup() {
        const router = useRouter();
        const { openCart, closeCart, clearCart, cart, showEmptyCartModal, isCartOpen } = useCart();
        const showClearCartModal = ref(false);
        const user = ref(store.state.user);

        const clearCartAssets = () => {
            clearCart();
            closeCart();
            showClearCartModal.value = false;
        };

        const canOpenCart = computed(() => {
            return !router.currentRoute.value.path.startsWith('/contracts/create');
        });

        return {
            showClearCartModal,
            openCart,
            closeCart,
            cart,
            showEmptyCartModal,
            isCartOpen,
            clearCartAssets,
            canOpenCart,
            user,
        };
    },
});
</script>
