<template>
    <div class="flex items-center justify-between py-3">
        <div class="flex justify-between flex-1 sm:hidden">
            <button
                @click="prev"
                class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md text-neutral-700 border-neutral-300 hover:text-neutral-500"
            >
                Previous
            </button>
            <button
                @click="next"
                class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium bg-white border rounded-md text-neutral-700 border-neutral-300 hover:text-neutral-500"
            >
                Next
            </button>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-neutral-700">
                    Showing
                    <span class="font-medium">{{ startResult }}</span>
                    to
                    <span class="font-medium">{{ endResult }}</span>
                    of
                    <span class="font-medium">{{ total }}</span>
                    results
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <button
                        @click="goTo(1)"
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border focus:outline-none focus:bg-primary-400 focus:text-white text-neutral-500 border-neutral-300 disabled:cursor-not-allowed rounded-l-md hover:bg-neutral-100 hover:text-neutral-600"
                        :disabled="modelValue === 1"
                    >
                        <span class="sr-only">First Page</span>
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <button
                        @click="goTo(modelValue - 1)"
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border focus:outline-none focus:bg-primary-400 focus:text-white text-neutral-500 border-neutral-300 disabled:cursor-not-allowed hover:bg-neutral-100 hover:text-neutral-600"
                        :disabled="modelValue === 1"
                    >
                        <span class="sr-only">Previous</span>
                        <!-- Heroicon name: solid/chevron-left -->
                        <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                    <button
                        v-for="page in visiblePages"
                        :key="page.value"
                        :disabled="!page.value"
                        @click="page.value ? goTo(page.value) : null"
                        class="relative inline-flex items-center px-4 py-2 text-sm font-medium border outline-none border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:bg-primary-400 focus:text-white"
                        :class="{
                            'bg-primary-600 text-white': page.value === modelValue,
                            'text-neutral-700 bg-white': page.value !== modelValue,
                            ' cursor-default': page.value === null,
                        }"
                    >
                        {{ page.label }}
                    </button>
                    <button
                        @click="goTo(modelValue + 1)"
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border focus:outline-none focus:bg-primary-400 focus:text-white hover:bg-neutral-100 hover:text-neutral-600 disabled:cursor-not-allowed text-neutral-500 border-neutral-300 hover:bg-neutral-50"
                        :disabled="modelValue === totalPages"
                    >
                        <span class="sr-only">Next</span>
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                    <button
                        @click="goTo(totalPages)"
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border focus:outline-none focus:bg-primary-400 focus:text-white text-neutral-500 border-neutral-300 disabled:cursor-not-allowed rounded-r-md hover:bg-neutral-100 hover:text-neutral-600"
                        :disabled="modelValue === totalPages"
                    >
                        <span class="sr-only">Last Page</span>

                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { usePagination } from 'vue-composable';

export default defineComponent({
    name: 'Pagination',
    // model: {
    //     prop: 'currentPage',
    //     event: 'update-current-page',
    // },
     model: {
        prop: 'modelValue',
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            number: Number,
        },
        pageSize: {
            number: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        startVisiblePages: {
            type: Number,
            default: 2,
        },
        endVisiblePages: {
            type: Number,
            default: 2,
        },
    },
    setup(props, { emit }) {
        const { currentPage, next, prev, lastPage } = usePagination({
            currentPage: props.modelValue,
            pageSize: props.pageSize,
            total: computed(() => props.total || 0),
        });

        const totalPages = computed(() => lastPage.value);

        const startResult = computed(() => {
            if (props.modelValue === 1) {
                return 1;
            }

            return (props.modelValue - 1) * props.pageSize + 1;
        });

        const endResult = computed(() =>
            startResult.value + props.pageSize > props.total ? props.total : startResult.value + props.pageSize - 1,
        );

        const visiblePages = computed(() => {
            const pages = [];
            if (props.startVisiblePages + props.endVisiblePages >= totalPages.value) {
                for (let p = 1; p <= totalPages.value; p++) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
                return pages;
            }

            if (props.modelValue - 1 > props.startVisiblePages) {
                pages.push({
                    value: null,
                    label: '...',
                });
            }

            for (let p = props.modelValue - props.startVisiblePages; p < props.modelValue; p++) {
                if (p > 0) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
            }

            pages.push({
                value: props.modelValue,
                label: props.modelValue,
            });

            for (let p = props.modelValue + 1; p <= props.modelValue + props.endVisiblePages; p++) {
                if (p <= totalPages.value) {
                    pages.push({
                        value: p,
                        label: p,
                    });
                }
            }

            if (props.modelValue + 1 < totalPages.value - props.endVisiblePages + 1) {
                pages.push({
                    value: null,
                    label: '...',
                });
            }

            return pages;
        });

        const goTo = (page) => {
            if (page !== null) {
                emit('update:modelValue', page);
            }
        };

        watch(
            () => currentPage.value,
            (page) => {
                emit('update:modelValue', page);
            },
        );

        return {
            next,
            prev,
            totalPages,
            startResult,
            endResult,
            visiblePages,
            goTo,
        };
    },
});
</script>
