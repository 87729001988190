import { secured } from '@/config/axios';

const resource = 'contracts';

export default {
    getAll: () => secured.get(`${resource}`),
    getActivePending: () => secured.get(`${resource}/active-pending`),
    get: id => secured.get(`${resource}/${id}`),
    getSignals: id => secured.get(`${resource}/${id}/signals`),
    create: data => secured.post(`${resource}`, data),
    activate: id => secured.put(`${resource}/${id}/activate`),
    activateCounterOffer: id => secured.put(`${resource}/${id}/activate-counter-offer`),
    negotiate: (id, data) => secured.put(`${resource}/${id}/negotiate`, data),
    counterOffer: (id, data) => secured.put(`${resource}/${id}/counter-offer`, data),
    decline: id => secured.put(`${resource}/${id}/decline`),
    declineCounterOffer: id => secured.put(`${resource}/${id}/decline-counter-offer`),
};
