<template>
    <div class="relative flex flex-col w-full space-y-1">
        <label v-if="label" :for="name" class="flex items-center text-sm font-medium tracking-wide text-neutral-600">
            <slot></slot>
            {{ label }}
        </label>
        <div class="flex mt-1 rounded-md shadow-sm">
            <span
                v-if="leadingText"
                class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50"
            >
                {{ leadingText }}
            </span>
            <Field :name="name" v-slot="{ errors, handleChange }" :value="inputValue">
                <input
                    :type="type"
                    :placeholder="placeholder"
                    v-model="inputValue"
                    @input="
                        $emit('update:modelValue', $event.target.value);
                        handleChange($event);
                    "
                    @change="handleChange"
                    @blur="handleChange"
                    class="block w-full px-3 py-2 shadow-sm appearance-none focus-within:z-10 placeholder-neutral-400 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    :class="[
                        { 'border-red-500': errors.length > 0 },
                        inputClasses,
                        { 'border rounded-md': !trailingText && !leadingText },
                        { 'border rounded-none': trailingText && leadingText },
                        { 'rounded-none rounded-l-md': trailingText && !leadingText },
                        { 'rounded-none rounded-r-md': leadingText && !trailingText },
                    ]"
                    :autocomplete="autocomplete"
                    :min="type === 'number' ? (min ? min : null) : null"
                    :max="type === 'number' ? (max ? max : null) : null"
                />
            </Field>
            <span
                v-if="trailingText"
                class="inline-flex items-center px-3 text-sm border border-l-0 text-neutral-500 border-neutral-300 rounded-r-md bg-neutral-50"
            >
                {{ trailingText }}
            </span>
        </div>
        <ErrorMessage class="text-sm text-red-600" :name="name" />
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';

export default defineComponent({
    name: 'InputField',
    components: {
        Field,
        ErrorMessage,
    },
    model: {
        prop: 'modelValue',
    },
    emits: ['update:modelValue'],
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Number],
        },
        autocomplete: {
            type: String,
            default: null,
        },
        min: {
            type: [Number, String],
        },
        max: {
            type: [Number, String],
        },
        inputClasses: {
            type: String,
        },
        trailingText: {
            type: String,
            default: null,
        },
        leadingText: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const inputValue = computed({
            set: newValue => {
                emit('update:modelValue', newValue);
            },
            get: () => props.modelValue,
        });

        return { inputValue };
    },
});
</script>
