<template>
    <div class="flex flex-1 w-full mt-3 sm:mt-0 sm:ml-4">
        <label for="header_search" class="sr-only">Search</label>
        <div class="flex rounded-md shadow-sm">
            <div class="relative focus-within:z-10">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SearchIcon class="w-5 h-5 text-neutral-400" aria-hidden="true" />
                </div>
                <input
                    id="header_search"
                    :value="modelValue.text"
                    :disabled="disabled"
                    type="text"
                    name="header_search"
                    class="block w-full pl-10 rounded-none border-neutral-300 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 rounded-l-md sm:hidden"
                    placeholder="Search..."
                    @input="updateProp(['text'], $event.target.value)"
                />
                <input
                    id="header_search_hidden"
                    :value="modelValue.text"
                    :disabled="disabled"
                    type="text"
                    name="header_search"
                    class="hidden w-full pl-10 rounded-none border-neutral-300 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 rounded-l-md sm:block sm:text-sm"
                    placeholder="Search..."
                    @input="updateProp(['text'], $event.target.value)"
                />
            </div>
            <div class="flex justify-items-end">
                <div id="dropdown" class="relative focus-within:z-10">
                    <button
                        type="button"
                        class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border border-neutral-300 text-neutral-700 bg-neutral-50 hover:bg-neutral-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 disabled:cursor-not-allowed disabled:text-neutral-400"
                        :class="{ 'rounded-r-md': filterOptions.length === 0 }"
                        :disabled="disabled"
                        @click="toggleSort"
                    >
                        <SortAscendingIcon
                            v-if="modelValue.sortOrder === 'asc'"
                            class="w-5 h-5 text-neutral-400 flex-0"
                            aria-hidden="true"
                        />
                        <SortDescendingIcon v-else class="w-5 h-5 text-neutral-400 flex-0" aria-hidden="true" />
                        <span class="flex-1 ml-2">{{ sortBy }}</span>
                        <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-neutral-400 flex-0" aria-hidden="true" />
                    </button>

                    <div v-if="isSortOpen" class="fixed inset-0" tabindex="-1" @click="isSortOpen = false"></div>

                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <div
                            v-if="isSortOpen"
                            class="absolute right-0 z-10 w-48 py-2 mt-1 text-sm bg-white border rounded-lg shadow-lg border-neutral-200"
                        >
                            <slot v-for="option in sortOptions" name="content" :option="option">
                                <a
                                    :key="option.path"
                                    :class="[
                                        'block px-4 py-1 hover:bg-primary-600 hover:text-white',
                                        option.key === modelValue.sortBy
                                            ? 'bg-primary-600 text-white'
                                            : 'text-neutral-800',
                                    ]"
                                    href="javascript:void(0)"
                                    @click="handleChangeSelect(option.key)"
                                    >{{ option.label }}</a
                                >
                            </slot>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="flex justify-items-end" v-if="filterOptions.length > 0">
                <div id="dropdown" class="relative focus-within:z-10">
                    <button
                        type="button"
                        class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border border-neutral-300 rounded-r-md text-neutral-700 bg-neutral-50 hover:bg-neutral-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 disabled:cursor-not-allowed disabled:text-neutral-400"
                        :disabled="disabled"
                        @click="toggleFilter"
                    >
                        <FilterIcon class="w-5 h-5 text-neutral-400 flex-0" aria-hidden="true" />
                        <span class="flex-1 ml-2 capitalize">{{ modelValue.filterBy }}</span>
                        <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-neutral-400 flex-0" aria-hidden="true" />
                    </button>
                    <div v-if="isFilterOpen" class="fixed inset-0" tabindex="-1" @click="isFilterOpen = false"></div>
                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <div
                            v-if="isFilterOpen"
                            class="absolute right-0 z-10 w-48 py-2 mt-1 text-sm bg-white border rounded-lg shadow-lg border-neutral-200"
                        >
                            <slot v-for="option in filterOptions" name="content" :option="option">
                                <a
                                    :key="option.path"
                                    :class="[
                                        'block px-4 py-1 hover:bg-primary-600 hover:text-white',
                                        option.key === modelValue.filterBy
                                            ? 'bg-primary-600 text-white'
                                            : 'text-neutral-800',
                                    ]"
                                    href="javascript:void(0)"
                                    @click="handleChangeFilter(option.key)"
                                    >{{ option.label }}</a
                                >
                            </slot>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda';
import { defineComponent, ref, computed } from 'vue';
import { SortAscendingIcon, SortDescendingIcon, SearchIcon, FilterIcon } from '@heroicons/vue/outline';
import { ChevronDownIcon } from '@heroicons/vue/solid';

export default defineComponent({
    name: 'SortedSearch',
    components: {
        ChevronDownIcon,
        SortAscendingIcon,
        SearchIcon,
        SortDescendingIcon,
        FilterIcon,
    },
    // model: {
    //     prop: 'search',
    //     event: 'search-changed',
    // },
    model: {
        prop: 'modelValue',
    },
    emits: ['update:modelValue'],
    props: {
        sortOptions: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Object,
        },
        filterOptions: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isSortOpen = ref(false);
        const isFilterOpen = ref(false);

        const toggleSort = () => {
            isFilterOpen.value = false;
            isSortOpen.value = !isSortOpen.value;
        };

        const toggleFilter = () => {
            isSortOpen.value = false;
            isFilterOpen.value = !isFilterOpen.value;
        };

        const sortBy = computed(
            () => R.find(R.propEq('key', props.modelValue.sortBy), props.sortOptions)?.label || 'Sort',
        );

        const updateProp = (path, value) => {
            emit('update:modelValue', R.assocPath(path, value, R.clone(props.modelValue)));
        };

        const handleChangeFilter = option => {
            emit('update:modelValue', {
                ...props.modelValue,
                sortOrder: props.modelValue.sortOrder,
                filterBy: option,
            });
            isFilterOpen.value = false;
        };

        const handleChangeSelect = option => {
            if (JSON.stringify(props.modelValue.sortBy) === JSON.stringify(option)) {
                emit('update:modelValue', {
                    ...props.modelValue,
                    sortOrder: props.modelValue.sortOrder === 'asc' ? 'desc' : 'asc',
                });
            } else {
                emit('update:modelValue', {
                    ...props.modelValue,
                    sortBy: option,
                    sortOrder: 'asc',
                });
            }
            isSortOpen.value = false;
        };

        // const handleEscape = (e: KeyboardEvent) => {
        //     if (e.key === 'Esc' || e.key === 'Escape') {
        //         isSortOpen.value = false;
        //         isFilterOpen.value = false;
        //     }
        // };

        // document.addEventListener('keydown', handleEscape);
        // root.$once('hook:beforeDestroy', () => {
        //     document.removeEventListener('keydown', handleEscape);
        // });

        return {
            handleChangeSelect,
            handleChangeFilter,
            toggleSort,
            isSortOpen,
            toggleFilter,
            isFilterOpen,
            updateProp,
            sortBy,
        };
    },
});
</script>
