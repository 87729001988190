<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden border-b shadow border-neutral-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-neutral-200">
                        <thead class="bg-neutral-50">
                            <tr>
                                <th
                                    v-for="field in fields"
                                    :key="field"
                                    scope="col"
                                    class="px-6 py-3 text-xs font-medium tracking-wider text-left uppercase text-neutral-500"
                                >
                                    {{ field.replace(/([a-z])([A-Z])/g, '$1 $2') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item['itemId']" class="bg-white border-b">
                                <td
                                    v-for="field in fields"
                                    :key="field"
                                    class="px-6 py-4 text-sm font-medium whitespace-nowrap text-neutral-700"
                                >
                                    {{ item[field] ?? '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'SimpleTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        itemId: {
            type: String,
            default: 'id',
        },
    },
    setup(props) {
        const fields = computed(() => (props.data && props.data.length > 0 ? Object.keys(props.data[0]) : []));

        return { fields };
    },
});
</script>
