<template>
    <div>
        <Field :name="name" v-slot="{ field, handleChange }" :value="modelValue">
            <Listbox as="div" v-model="field.value">
                <ListboxLabel :for="name" class="block text-sm font-medium text-neutral-700" v-if="label">
                    {{ label }}
                </ListboxLabel>

                <div class="relative mt-1">
                    <ListboxButton
                        :disabled="disabled"
                        class="relative w-full py-2 pl-3 pr-10 text-left bg-white border rounded-md shadow-sm cursor-default border-neutral-300 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:bg-neutral-100 disabled:opacity-75 disabled:cursor-not-allowed"
                    >
                        <span class="block truncate" v-if="optionLabel">{{ optionLabel }}</span>
                        <span class="block truncate text-neutral-400" v-else-if="placeholder">{{ placeholder }}</span>
                        <span class="block pt-2 pb-3 truncate" v-else />
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ">
                            <ChevronDownIcon class="w-5 h-5 text-neutral-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <ListboxOptions
                            class="absolute z-10 w-full overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                            <ListboxOption
                                as="template"
                                v-for="(option, idx) in options"
                                :key="idx"
                                :value="option.value ? option.value.toString() : option"
                                v-slot="{ active }"
                            >
                                <li
                                    :class="[
                                        active ? 'text-white bg-primary-600' : 'text-neutral-900',
                                        'cursor-pointer select-none relative py-2 pl-3 pr-9',
                                    ]"
                                    @click="
                                        updateValue(option.value ? option.value : option, field.value);
                                        handleChange(
                                            (option.value ? option.value : option) === field.value
                                                ? null
                                                : option.value
                                                ? option.value
                                                : option,
                                        );
                                    "
                                >
                                    <span
                                        :class="[
                                            field.value === (option.value ? option.value : option)
                                                ? 'font-semibold'
                                                : 'font-normal',
                                            'block truncate',
                                        ]"
                                    >
                                        {{ option.label ? option.label : option }}
                                    </span>

                                    <span
                                        v-if="field.value === (option.value ? option.value : option)"
                                        :class="[
                                            active ? 'text-white' : 'text-primary-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                        ]"
                                    >
                                        <XIcon class="w-5 h-5" aria-hidden="true" v-if="active" />
                                        <CheckIcon class="w-5 h-5" aria-hidden="true" v-else />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
                <ErrorMessage class="text-sm text-red-600" :name="name" />
            </Listbox>
        </Field>
        <div v-if="description" class="absolute text-sm italic text-neutral-500">
            {{ description }}
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/vue/solid';

export default defineComponent({
    name: 'SelectMenu',
    model: {
        prop: 'modelValue',
    },
    emits: ['update:modelValue', 'changed'],
    components: {
        Field,
        ErrorMessage,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        ChevronDownIcon,
        XIcon,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: [String, Object, Number],
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const updateValue = (value, beforeValue) => {
            if (beforeValue === value) {
                emit('update:modelValue', null);
                emit('changed', null);
            } else {
                emit('update:modelValue', value);
                emit('changed', value);
            }
        };

        const description = computed(() => {
            const option = props.options.find(option => option.value == props.modelValue);
            if (option && option.description) return option.description;
            return null;
        });

        const optionLabel = computed(() => {
            const selectedOption = props.options.find(option => option.value === props.modelValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return props.modelValue;
        });

        return {
            updateValue,
            description,
            optionLabel,
        };
    },
});
</script>
