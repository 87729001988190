import axios from 'axios';
// import store from '@/store';
import { useRouter } from 'vue-router';

const baseURL = '/api/';
const router = useRouter();

const secured = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const plain = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

secured.interceptors.request.use(async config => {
    // const token = store.getters.getKeycloakToken;

    // if (token) {
    //     // eslint-disable-next-line no-param-reassign
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
});

plain.interceptors.request.use(async config => {
    // const token = store.getters.getKeycloakToken;

    // if (token) {
    //     // eslint-disable-next-line no-param-reassign
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
});

const handleErrorResponse = async error => {
    if (error.response && error.response.status === 500) {
        await router.push({ name: 'error-500' });
    }
    return Promise.reject(error);
};

plain.interceptors.response.use(null, handleErrorResponse);
secured.interceptors.response.use(null, handleErrorResponse);

export { plain, secured };
