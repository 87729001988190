<template>
    <div class="flex flex-col min-h-screen">
        <page-header />
        <main class="flex flex-col flex-1 overflow-hidden bg-neutral-50">
            <router-view />
        </main>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import PageHeader from './components/PageHeader.vue';

export default defineComponent({
    name: 'EmptyLayout',
    components: {
        PageHeader,
    },
});
</script>
