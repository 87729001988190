import store from '../store';
import { computed, ref } from 'vue';
import { ContractsAPI } from '@/api';

export function useCart() {
    const isCartOpen = ref(false);
    const showEmptyCartModal = ref(false);

    const openCart = () => {
        isCartOpen.value = false;
    };

    const closeCart = () => {
        isCartOpen.value = false;
    };

    const clearCart = () => {
        store.commit('CLEAR_CART');
        localStorage.setItem('cart', JSON.stringify([]));
    };

    const getCartFromLocalStorage = () => {
        let cartAssets = localStorage.getItem('cart');
        if (cartAssets) cartAssets = JSON.parse(cartAssets);
        else cartAssets = [];
        return cartAssets;
    };

    store.commit('SET_CART', getCartFromLocalStorage());
    const cart = computed(() => store.state.cart);
    const cartAssetIds = computed(() => cart.value.map(asset => asset.id));

    const addToCart = asset => {
        store.commit('ADD_IN_CART', asset);
        localStorage.setItem('cart', JSON.stringify(cart.value));
    };

    const removeFromCart = assetId => {
        store.commit('REMOVE_FROM_CART', parseInt(assetId, 10));
        localStorage.setItem('cart', JSON.stringify(cart.value));
    };

    const assetsInContracts = ref([]);

    ContractsAPI.getActivePending().then(res => {
        assetsInContracts.value = res.data;
    });

    return {
        isCartOpen,
        openCart,
        closeCart,
        cart,
        cartAssetIds,
        showEmptyCartModal,
        addToCart,
        removeFromCart,
        clearCart,
        assetsInContracts,
    };
}
