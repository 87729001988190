// Functions for the Core
const coreABI = [
    'event FlexibilityContractCreated(address newContract)',
    'function createFlexibilityContract(address _buildingOccupantEthAddress, uint256 _issueTime, uint256 _startDate, uint256 _endDate, string _flexibilityCapacity, string[] _weekDaysAndTimes, string _assets, string _utilizationAvailabilityPenalty, string _totalDailyActivations)',
];

// Functions for the FlexibilityContract
const contractABI = [
    'function acceptContract()',
    'function acceptOffer()',
    'function rejectContract()',
    'function rejectOffer()',
    'function negotiate(string memory _utilizationAvailabilityPenalty, string memory _totalDailyActivations)',
    'function counterOffer(string _utilizationAvailabilityPenalty, string _totalDailyActivations)',
    'function isContractActive() view returns (bool)',
];

export { coreABI, contractABI };
