import store from '@/store';
import Keycloak from 'keycloak-js';
import * as R from 'ramda';
import AuthAPI from './auth';

let keycloak = null;
if (
    !R.isNil(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_CLIENT_ID) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_CLIENT_ID)
) {
    keycloak = new Keycloak({
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM || '',
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
    });
}

const keycloakService = {
    isEnabled: () => {
        return !R.isNil(keycloak);
    },
    isAuthenticated: () => {
        return keycloak.authenticated && !keycloak.isTokenExpired();
    },
    login: async (to, next) => {
        return new Promise((resolve, reject) => {
            if (!R.isNil(keycloak)) {
                keycloak
                    .init({ onLoad: 'login-required', promiseType: 'native', checkLoginIframe: false })
                    .then(async authenticated => {
                        store.commit('SET_KEYCLOAK_TOKEN', keycloak.idToken);

                        if (authenticated) {
                            await AuthAPI.keycloakLogin(keycloak.subject, keycloak.idToken, keycloak.refreshToken)
                                .then(async () => {
                                    store.commit('CLEAR_KEYCLOAK_TOKEN');
                                    const { data } = await AuthAPI.user();
                                    store.commit('SET_USER', data);

                                    if (process.env.VUE_APP_ETH_NODE) {
                                        await store.commit('SET_WALLET', data.ethwallet);
                                        store.dispatch('createProvider', process.env.VUE_APP_ETH_NODE);
                                    }

                                    if (to && data.organisationId && data.isVerified) {
                                        // await router.push({ name: to });
                                        await next(to);
                                    }

                                    resolve(true);
                                })
                                .catch(async err => {
                                    if (err.response?.data?.message || err.response?.data?.statusCode === 403) {
                                        reject(
                                            new Error(
                                                err.response.data.statusCode === 403
                                                    ? 'The account is not activated'
                                                    : err.response.data.message,
                                            ),
                                        );

                                        // another solution would be to redirect the user to an error page with logout button
                                        if (
                                            err.response?.data?.message ===
                                            'Your organization has no access in this application'
                                        ) {
                                            setTimeout(() => {
                                                keycloak.logout();
                                            }, 4000);
                                        }
                                    }
                                    if (err.message) {
                                        reject(new Error(err.message));
                                    }
                                    reject(new Error('Failed to login using keycloak'));
                                });
                        }
                        resolve(true);
                    })
                    .catch(() => {
                        reject(new Error('Authenticated Failed'));
                    });
            }
        });
    },
    logout: async () => {
        if (!R.isNil(keycloak)) {
            keycloak.init({ onLoad: 'login-required', promiseType: 'native' });
            // store.commit('CLEAR_USER');
            store.commit('CLEAR_KEYCLOAK_TOKEN');
            await keycloak.logout();
        }
    },
};

export default keycloakService;
