<template>
    <perfect-scrollbar :class="[additionalClasses, totalClasses]" ref="scrollbarRef">
        <slot></slot>
    </perfect-scrollbar>
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.esm' // bug of scrollbar for vue3, it should have been imported from 'vue3-perfect-scrollbar' 
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
    name: 'Scrollbar',
    components: { PerfectScrollbar },
    props: {
        additionalClasses: {
            type: String,
            default: '',
        },
        overflowY: {
            type: Boolean,
            default: true,
        },
        overflowX: {
            type: Boolean,
            default: false,
        },
        scrollUp: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const scrollbarRef = ref(null);
        const baseClasses = [];

        const totalClasses = computed(() => {
            const calculatedClasses = baseClasses;
            if (props.overflowX) {
                calculatedClasses.push('overflow-x-auto');
            }
            if (props.overflowY) {
                calculatedClasses.push('overflow-y-auto');
            }
            return calculatedClasses;
        });

        if (props.scrollUp) {
            scrollbarRef.value.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return { totalClasses, scrollbarRef };
    },
});
</script>
