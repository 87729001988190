import { ethers } from 'ethers';
import { createStore } from 'vuex';

const store = createStore({
    state: {
        user: null,
        cart: [],
        wallet: null,
        provider: null,
        network: null,
        keycloakToken: null,
        keycloakRefreshToken: null,
        contractAddress: process.env.VUE_APP_ETH_CONTRACT ?? '',
    },
    mutations: {
        SET_USER(state, newUser) {
            state.user = newUser;
        },
        CLEAR_USER(state) {
            state.user = null;
            state.wallet = null;
        },
        ADD_IN_CART(state, asset) {
            state.cart.push(asset);
        },
        REMOVE_FROM_CART(state, assetId) {
            state.cart = state.cart.filter(item => item.id !== assetId);
        },
        CLEAR_CART(state) {
            state.cart = [];
        },
        SET_WALLET(state, wallet) {
            state.wallet = wallet;
        },
        SET_PROVIDER(state, provider) {
            state.provider = provider;
        },
        SET_NETWORK(state, network) {
            state.network = network;
        },
        CLEAR_NETWORK(state) {
            state.provider = null;
            state.network = null;
        },
        SET_CART(state, cart) {
            state.cart = cart;
        },
        // ADD_TO_CART(state, asset) {
        //     state.cart.push(asset);
        // },
    },
    getters: {
        isAuthenticated: state => !!state.user,
        isAggregator: state => state.user && state.user.type === 'Aggregator',
        hasWallet: state => !!state.wallet,
        address: state => {
            if (state.wallet) {
                return JSON.parse(state.wallet).address;
            }
            return null;
        },
        networkName: state => {
            if (state.network) {
                switch (state.network.chainId) {
                    case 1:
                        return 'Main Ethereum Network';
                    case 3:
                        return 'Ropsten Test Network';
                    case 4:
                        return 'Rinkeby Test Network';
                    case 5:
                        return 'Goerli Test Network';
                    case 42:
                        return 'Kovan Test Network';
                    case 1337:
                        return 'Ganache Test Network';
                    case 4224:
                        return 'EXCESS Ethereum Network';
                    default:
                        return 'Unknown Network';
                }
            }
            return null;
        },
    },
    actions: {
        setWallet({ state, commit }) {
            const { user } = state;
            if (user && user.ethwallet) {
                commit('SET_WALLET', user.ethwallet);
            }
        },
        importWallet(_, { privateKey, password, callback }) {
            const wallet = new ethers.Wallet(privateKey);
            return wallet.encrypt(password, callback);
        },
        createWallet(_, { password, callback }) {
            const randomWallet = ethers.Wallet.createRandom(ethers.utils.randomBytes(32));
            return randomWallet.encrypt(password, callback);
        },
        async createProvider({ commit }, url) {
            const provider = new ethers.providers.JsonRpcProvider(url);
            commit('SET_PROVIDER', provider);

            if (provider) {
                try {
                    const network = await provider.getNetwork();
                    commit('SET_NETWORK', network);
                } catch (_) {
                    commit('SET_NETWORK', null);
                }
            } else {
                commit('SET_NETWORK', null);
            }
        },
    },
});

export default store;
