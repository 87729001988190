<template>
    <button
        type="button"
        class="flex items-center text-xs font-medium text-white border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        :class="{
            'px-2.5 py-1 text-sm': size === 'xs',
            'px-3 py-1.5 text-sm': size === 'sm',
            'px-4 py-2 text-sm': size === 'base',
            'px-4 py-2 text-base': size === 'lg',
            'px-6 py-3 text-base': size === 'xl',
            'bg-primary-600 hover:bg-primary-700 focus:ring-primary-500': variant === 'primary',
            'bg-secondary-700 hover:bg-secondary-600 focus:ring-secondary-600': variant === 'secondary',
            'bg-red-600 hover:bg-red-700 focus:ring-red-500': variant === 'red',
            'bg-green-600 hover:bg-green-700 focus:ring-green-500': variant === 'green',
            'bg-neutral-600 hover:bg-neutral-700 focus:ring-neutral-500': variant === 'neutral',
            'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500': variant === 'yellow',
            'bg-white hover:bg-neutral-50 focus:ring-primary-700 text-primary-500 border-primary-400':
                variant === 'light',
        }"
        :disabled="disabled"
    >
        <slot />
    </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PrimaryButton',
    props: {
        size: { type: String, default: 'base' },
        variant: { type: String, default: 'primary' },
        disabled: { type: Boolean, default: false },
    },
});
</script>
