<template>
    <span class="relative z-0 inline-flex" :class="{ 'shadow-md': shadow }">
        <button
            v-for="(item, idx) in options"
            v-tooltip="getItemTooltip(item)"
            :key="idx"
            type="button"
            class="relative inline-flex items-center px-2 py-1 -ml-px text-sm font-medium leading-5 capitalize transition duration-150 ease-in-out border xl:px-4 focus:z-10 focus:outline-none focus:border-neutral-600 focus:ring-neutral active:bg-neutral-100 active:text-neutral-700"
            :class="{
                'bg-secondary-700 text-secondary-100 pointer-events-none border-secondary-700':
                    activeItem === item.value,
                'text-primary-700  border-secondary-300 hover:bg-secondary-200 ': activeItem !== item.value,
                'bg-white': activeItem !== item.value && whiteBackground,
                'bg-neutral-200': activeItem !== item.value && !whiteBackground,
                'rounded-l': idx === 0,
                'rounded-r': idx === options.length - 1,
                'flex-1 ': fullWidth,
            }"
            @click="$emit('change-filter', item.value)"
        >
            <div :class="{ 'w-full text-center': fullWidth }">{{ getItemLabel(item) }}</div>
        </button>
    </span>
</template>

<script>
import * as R from 'ramda';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ButtonGroup',
    model: {
        prop: 'activeItem',
        event: 'click',
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        activeItem: {
            type: String,
            required: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        whiteBackground: {
            type: Boolean,
            default: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { is } = R;
        const getItemLabel = item => {
            return is(Object, item) ? item.text : item;
        };
        const getItemTooltip = item => {
            return is(Object, item) ? item.tooltip : '';
        };
        return { getItemLabel, getItemTooltip };
    },
});
</script>
