<template>
    <div class="h-full overflow-hidden bg-neutral-50">
        <router-view />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EmptyLayout',
});
</script>
