import { contractABI, coreABI } from '@/config/abi';
import store from '@/store';
import { ethers } from 'ethers';
import { computed, ref } from 'vue';

export function useBlockchain() {
    const message = ref('');

    const messages = {
        Connecting: 'Connecting to blockchain...',
        Transaction: 'Waiting for transaction to be mined and confirmed. This might take a while...',
        Database: 'Saving to EXCESS database',
    };

    const contractAddress = computed(() => store.state.contractAddress);

    const createContract = async (wallet, contract) => {
        message.value = messages.Connecting;
        const core = new ethers.Contract(contractAddress.value, coreABI, wallet);

        message.value = 'Creating Smart Contract...';
        const utilization = contract.utilization[contract.utilization.length - 1];
        const availability = contract.availability[contract.availability.length - 1];
        const penalty = contract.penalty[contract.penalty.length - 1];
        const dailyActivations = contract.dailyActivations[contract.dailyActivations.length - 1];
        const totalActivations = contract.totalActivations[contract.totalActivations.length - 1];
        let weekDaysAndTimes = [];
        contract.weekDaysAndTimes.forEach(weekDayAndTimes => {
            weekDaysAndTimes.push(`${weekDayAndTimes.weekDay},${weekDayAndTimes.startTime},${weekDayAndTimes.endTime}`);
        });

        const tx = await core.createFlexibilityContract(
            `0x${contract.providerEthAddress}`,
            contract.issueTime,
            contract.startPeriod,
            contract.endPeriod,
            contract.flexibilityCapacity.toString(),
            weekDaysAndTimes,
            contract.assets.join(','),
            `${utilization},${availability},${penalty}`,
            `${totalActivations},${dailyActivations}`,
        );

        message.value = messages.Transaction;
        const receipt = await tx.wait();
        const contractCreated = receipt.events.pop();
        message.value = messages.Database;
        return contractCreated.args[0];
    };

    const accept = async (wallet, ethaddress) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(ethaddress, contractABI, wallet);
        message.value = 'Activating Smart Contract...';
        const tx = await smartContract.acceptContract();
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    const reject = async (wallet, ethaddress) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(ethaddress, contractABI, wallet);
        message.value = 'Rejecting Smart Contract...';
        const tx = await smartContract.rejectContract();
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    const acceptOffer = async (wallet, ethaddress) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(ethaddress, contractABI, wallet);
        message.value = 'Activating Smart Contract...';
        const tx = await smartContract.acceptOffer();
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    const rejectOffer = async (wallet, ethaddress) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(ethaddress, contractABI, wallet);
        message.value = 'Rejecting Smart Contract...';
        const tx = await smartContract.rejectOffer();
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    const negotiate = async (wallet, contract) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(contract.ethAddress, contractABI, wallet);
        message.value = 'Writing updated Smart Contract...';
        const utilization = contract.utilization[contract.utilization.length - 1];
        const availability = contract.availability[contract.availability.length - 1];
        const penalty = contract.penalty[contract.penalty.length - 1];
        const dailyActivations = contract.dailyActivations[contract.dailyActivations.length - 1];
        const totalActivations = contract.totalActivations[contract.totalActivations.length - 1];
        const tx = await smartContract.negotiate(
            `${utilization},${availability},${penalty}`,
            `${totalActivations},${dailyActivations}`,
        );
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    const counterOffer = async (wallet, contract) => {
        message.value = messages.Connecting;
        const smartContract = new ethers.Contract(contract.ethAddress, contractABI, wallet);
        message.value = 'Writing updated Smart Contract...';
        const utilization = contract.utilization[contract.utilization.length - 1];
        const availability = contract.availability[contract.availability.length - 1];
        const penalty = contract.penalty[contract.penalty.length - 1];
        const dailyActivations = contract.dailyActivations[contract.dailyActivations.length - 1];
        const totalActivations = contract.totalActivations[contract.totalActivations.length - 1];
        const tx = await smartContract.counterOffer(
            `${utilization},${availability},${penalty}`,
            `${totalActivations},${dailyActivations}`,
        );
        message.value = messages.Transaction;
        await tx.wait();
        message.value = messages.Database;
    };

    return {
        message,
        createContract,
        accept,
        reject,
        acceptOffer,
        rejectOffer,
        negotiate,
        counterOffer,
    };
}
