<template>
    <SwitchGroup as="div" class="flex items-center" :class="additionalClasses">
        <SwitchLabel as="span" class="w-4/5" passive v-if="label">
            <slot :label="label">
                <span class="text-neutral-700">{{ label }}</span>
            </slot>
            <span class="text-sm text-neutral-500">{{ description }}</span>
        </SwitchLabel>
        <Field name="toggle" v-slot="{ field, handleChange }" :value="modelValue" class="w-1/5">
            <Switch
                :value="field.value"
                @click="
                    $emit('update:modelValue', !modelValue);
                    $emit('change', !modelValue);
                "
                :class="[
                    modelValue ? 'bg-primary-600' : 'bg-neutral-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                ]"
                @input="$emit('update:modelValue', $event.target.value)"
                @change="handleChange"
            >
                <span
                    aria-hidden="true"
                    :class="[
                        modelValue ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                />
            </Switch>
        </Field>
    </SwitchGroup>
</template>
<script>
import { defineComponent } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { Field } from 'vee-validate';

export default defineComponent({
    name: 'SwitchToggle',
    components: {
        Field,
        Switch,
        SwitchGroup,
        SwitchLabel,
    },
    props: {
        label: {
            type: String,
        },
        description: {
            type: String,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        additionalClasses: {
            type: String,
            default: 'w-full md:w-4/5 lg:w-3/5',
        },
    },
});
</script>
