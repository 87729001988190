<template>
    <li class="flex p-4">
        <div class="flex flex-col flex-1 ">
            <div>
                <div class="flex justify-between text-base font-medium text-gray-900">
                    <h3>
                        {{ asset.title }}
                    </h3>
                    <div class="flex space-x-4 text-sm">
                        <a href="javascript:void(0)" class="text-sm text-red-500 hover:text-red-700">
                            <TrashIcon class="w-4 h-6" @click="removeFromCart(asset.id)" />
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="flex items-end justify-between flex-1 mt-1 text-sm text-gray-500">
                <p v-if="asset.organisation">{{ asset.organisation.businessName }}</p>
                <p v-else>{{ asset.createdBy.firstName }} {{ asset.createdBy.lastName }}</p>
                <div class="flex text-sm"></div>
            </div> -->
        </div>
    </li>
</template>

<script>
import { defineComponent } from 'vue';
import { TrashIcon } from '@heroicons/vue/outline';
import { useCart } from '../composables';

export default defineComponent({
    name: 'CartListItem',
    components: { TrashIcon },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { removeFromCart } = useCart();

        return {
            removeFromCart,
        };
    },
});
</script>
