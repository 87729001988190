import { ContractsAPI } from '@/api';
import { useUtils } from '@/composables';
import * as R from 'ramda';
import { computed, ref, watch } from 'vue';
import { ContractStatus } from '../views/contract/constants/contract.constants';

const { formatTime } = useUtils();

export function useContracts(
    pageSize = 10,
    search = ref({ text: '', sortBy: 'id', sortOrder: 'desc', filterBy: 'all' }),
    page = ref(1),
    getContracts = false,
) {
    const contracts = ref([]);
    const loading = ref(false);

    if (getContracts) {
        loading.value = true;
        ContractsAPI.getAll()
            .then(res => {
                contracts.value = res.data;
                loading.value = false;
            })
            .catch(() => (loading.value = false));
    }

    const prepareContractPayload = (contract, negotiable) => {
        const payload = R.clone(contract);
        payload.providerEthAddress = payload.assets[0].provider.ethaddress;
        payload.assets = payload.assets.map(asset => asset.id);
        payload.utilization.push(Number(negotiable.utilization));
        payload.availability.push(Number(negotiable.availability));
        payload.penalty.push(Number(negotiable.penalty));
        payload.totalActivations.push(Number(negotiable.totalActivations));
        payload.dailyActivations.push(Number(negotiable.dailyActivations));
        payload.startPeriod = payload.startPeriod.getTime() / 1000;
        payload.endPeriod = payload.endPeriod.getTime() / 1000;
        payload.flexibilityCapacity = Number(payload.flexibilityCapacity);
        for (let i = 0; i < payload.weekDaysAndTimes.length; i += 1) {
            payload.weekDaysAndTimes[i].startTime = formatTime(payload.weekDaysAndTimes[i].startTime);
            payload.weekDaysAndTimes[i].endTime = formatTime(payload.weekDaysAndTimes[i].endTime);
        }
        payload.issueTime = Math.round(new Date().setUTCHours(0, 0, 0, 0) / 1000);
        return payload;
    };

    const setPage = newPage => {
        page.value = newPage;
    };

    const sortedContracts = computed(() => {
        const sortBy = JSON.stringify(search.value.sortBy);
        const valueSortBy = JSON.parse(sortBy);
        if (search.value.sortOrder === 'asc') {
            return R.sortWith([R.ascend(R.path(valueSortBy))], contracts.value);
        }
        return R.sortWith([R.descend(R.path(valueSortBy))], contracts.value);
    });

    const searchedContracts = computed(() => {
        let searchedContractsClone = R.clone(sortedContracts.value);
        if (search.value.text.trim() !== '') {
            searchedContractsClone = searchedContractsClone.filter(contract =>
                contract.id.toString().includes(R.toLower(search.value.text)),
            );
        }
        return searchedContractsClone;
    });

    const filteredContracts = computed(() => {
        let searchedContractsClone = R.clone(searchedContracts.value);
        if (search.value.filterBy !== 'all') {
            searchedContractsClone = searchedContractsClone.filter(
                contract => contract.status === ContractStatus[search.value.filterBy],
            );
        }
        return searchedContractsClone;
    });

    const visibleContracts = computed(() => {
        return filteredContracts.value.slice((page.value - 1) * pageSize, (page.value - 1) * pageSize + pageSize);
    });

    watch(
        () => [search.value.text, search.value.sortBy],
        () => {
            setPage(1);
        },
    );

    const statusClasses = status => {
        switch (status) {
            case ContractStatus.offer:
                return 'text-blue-600 bg-blue-100';
            case ContractStatus.negotiate:
                return 'text-orange-600 bg-orange-100';
            case ContractStatus.active:
                return 'text-green-600 bg-green-100';
            case ContractStatus.closed:
                return 'text-neutral-600 bg-neutral-100';
            case ContractStatus.rejected:
                return 'text-red-600 bg-red-100';
            default:
                return 'text-yellow-600 bg-yellow-100';
        }
    };

    return {
        contracts,
        filteredContracts,
        visibleContracts,
        page,
        setPage,
        prepareContractPayload,
        statusClasses,
        loading,
    };
}
